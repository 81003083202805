import React, { Component } from 'react'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'
import arrowRight from '../assets/images/video-arrow-white.svg'

import { mq } from '../utils/media-queries'
import logo from '../assets/images/header-logo.png'
import bgLines from '../assets/images/bg-lines.png'
import QuestionsHeaderIcon from '../assets/images/questions-header-icon.svg'

/*
Must be a class component due to limitations of react-sticky package
https://github.com/captivationsoftware/react-sticky/issues/252
*/
class Header extends Component {
  state = {
    isMobileNavOpen: false,
    headerTab: '',
    hovered: 0,
  }

  componentDidMount = () => {
    this.handleURLTab()
  }

  handleHeaderTab = (val) => {
    this.setState({
      headerTab: val
    })
  }

  handleHovered = (val) => {
    this.setState({
      hovered: val
    })
  }

  handleURLTab = () => {
    if (typeof window !== "undefined") {
      let path = window.location.href;
      let pathAfterSlash = path.split('/')[3];
      this.handleHeaderTab(pathAfterSlash)
    }
  }


  render() {

    const { headerTab, hovered } = this.state;
    return (
      <header className="sticky-container"
        //style={this.props.style}
        css={
          !this.state.isMobileNavOpen ?
            css`
              z-index: 9999;
              background-color: white;
              ${[mq[2]]} {
                z-index: 9999;
                width: 100%;
              }
            `
            :
            css`
              z-index: 9999;
              background-color: white;
              ${[mq[2]]} {
                position: fixed;
                z-index: 9999;
                width: 100%;
              }
            `
        }
      >

        <Container
          css={css`
            :after {
              content: '';
              display: block;
              /* When fixed header logic is in place, implement
                shadow when not at top of page */
              /* -webkit-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.5);
                box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.5); */
              border-bottom: 1px solid #8c8d91;
            }
          `}
        >
          <Row>
            <Col>
              <div
                css={css`
                  background: #ffffff;
                  /*height: 30px;*/
                  color: #616161;

                  padding-top: 10px;
                  font-size: 14px;
                  position: relative;
                  text-align: right;
                  ${[mq[2]]} {
                    text-align: center;
                    font-size: 12px;
                    padding-top: 4px;
                  }
                `}
              >
                This website is intended for US residents 18 years of age and older
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={3}
              css={css`
                display: flex;
                justify-content: space-between;
                /* @media screen and (max-width: 992px) {
                  padding-right: 0;
                } */
              `}
            >
              <a
                css={css`
                  display: inline-block;
                `}
                href='/'
                data-testid='header_link_logoLink'
                id='header_link_logo'
              >
                <img
                  loading='lazy'
                  css={css`
                    width: 209px;
                    margin: auto 0 20px 0;
                    ${[mq[2]]} {
                      width: 110px;
                      margin: 20px 0;
                    }
                  `}
                  src={logo}
                  alt='Hodgkin Hub logo'
                />
              </a>

              <button
                className={`navbar-toggle ${this.state.isMobileNavOpen &&
                  'active'}`}
                type='button'
                onClick={() => {
                  this.setState({
                    isMobileNavOpen: !this.state.isMobileNavOpen,
                  })
                }}
                css={css`
                  display: none;
                  position: relative;
                  margin: 0;
                  height: 100%;
                  width: 89px;

                  background: white;
                  border: 0;

                  ${[mq[2]]} {
                    display: flex;
                  }
                  ${[mq[1]]} {
                    display: flex;
                  }

                  &.active {
                    background: linear-gradient(
                      180deg,
                      #ae58cf 0%,
                      #7e3f97 100%
                    );
                    .icon-bar {
                      background: white;
                    }
                  }

                  &:focus {
                    outline: none;
                  }

                  .icon-bar {
                    margin: 0;
                    display: block;
                    background: #01aef1;
                    width: 50px;
                    height: 4px;
                    position: absolute;
                    right: 20px;
                  }

                  .icon-bar:nth-of-type(2) {
                    top: 30px;
                  }

                  .icon-bar:nth-of-type(3) {
                    top: 45px;
                  }

                  .icon-bar:nth-of-type(4) {
                    top: 60px;
                  }
                `}
              >
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar' />
                <span className='icon-bar' />
                <span className='icon-bar' />
              </button>
            </Col>

            <Col
              css={css`
                padding-top: 48px;
                ${[mq[2]]} {
                  display: none;
                }
              `}
              lg={9}
            >
              <Row>
                <Col>
                  <nav
                    css={css`
                      width: 100%;
                      display: flex;
                      align-items: flex-end;
                    `}
                  >
                    <ul
                      css={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        width: 100%;
                        padding: 0;
                        list-style-type: none;
                        z-index: 1000;
                        li {
                          width: 25%;
                          height: 83px;
                          text-align: center;
                          font-size: 14px;
                          font-weight: bold;
                          line-height: 16px;
                          &:first-of-type,
                          :nth-of-type(2) {
                            border-right: 0.5px solid #8c8d91;
                          }
                        }
                        a {
                          /* TODO: for selected, add border height to padding
                           so text doesn't jump
                        */
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 100%;
                          height: 100%;
                          padding: 0 10px;
                          text-decoration: none;
                          color: inherit;
                        }
                      `}
                    >
                      <li
                        onClick={() => {
                          this.handleHeaderTab('about')
                        }}
                        onMouseEnter={() => {
                          this.handleHovered(1)
                        }}
                        onMouseLeave={() => {
                          this.handleHovered(0)
                        }}
                        style={
                          headerTab === 'about' || hovered === 1 ?
                            { color: '#01aef1', fontWeight: 'bold', borderBottom: '5px solid #01aef1', transition: '.2s all' } :
                            { transition: '.2s all' }
                        }
                      >
                        <a id='header_link_about' href='/about/'>
                          ABOUT HODGKIN LYMPHOMA
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          this.handleHeaderTab('videos')
                        }}
                        onMouseEnter={() => {
                          this.handleHovered(2)
                        }}
                        onMouseLeave={() => {
                          this.handleHovered(0)
                        }}
                        style={
                          headerTab === 'videos' || hovered === 2 ?
                            { color: '#01aef1', fontWeight: 'bold', borderBottom: '5px solid #01aef1', transition: '.2s all' } :
                            { transition: '.2s all' }
                        }
                      >
                        <a id='header_link_videos' href='/videos/'>
                          VIDEOS
                          {/* <img
                            loading='lazy'
                            css={css`
                              margin-left: 16px;
                              height: 14px;
                            `}
                            src={arrowDown}
                            alt=''
                          /> */}
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          this.handleHeaderTab('resources')
                        }}
                        onMouseEnter={() => {
                          this.handleHovered(3)
                        }}
                        onMouseLeave={() => {
                          this.handleHovered(0)
                        }}
                        style={
                          headerTab === 'resources' || hovered === 3 ?
                            { color: '#01aef1', fontWeight: 'bold', borderBottom: '5px solid #01aef1', transition: '.2s all' } :
                            { transition: '.2s all' }
                        }
                      >
                        <a id='header_link_resources' href='/resources/'>
                          RESOURCES
                          {/* <img
                            loading='lazy'
                            css={css`
                              margin-left: 16px;
                              height: 14px;
                            `}
                            src={arrowDown}
                            alt=''
                          /> */}
                        </a>
                      </li>
                      <li
                        css={css`
                          /* lines listed first in order to be on top of the bg color
                        source: https://stackoverflow.com/questions/8195215/css-background-image-on-background-color/47964080#47964080
                        */
                          background: url(${bgLines}),
                            linear-gradient(180deg, #ae58cf 0%, #7e3f97 100%);
                          color: white;
                          border-radius: 8px 8px 0 0;
                        `}
                      >
                        <a
                          css={css``}
                          href='/hodgkin-lymphoma-treatment-discussion-guide'
                        // href={questionsPdf}
                        // target='_blank'
                        // rel='noopener noreferrer'
                        // id='header_link_questionsPdf'
                        >
                          <div
                            css={css`
                              width: 80px;
                            `}
                          >
                            <img
                              loading='lazy'
                              src={QuestionsHeaderIcon}
                              css={css`
                                width: 100%;
                              `}
                            ></img>
                          </div>
                          HODGKIN LYMPHOMA TREATMENT DISCUSSION GUIDE
                        </a>
                      </li>
                    </ul>
                  </nav>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div
          className={`mobile-nav ${this.state.isMobileNavOpen && 'opened'}`}
          css={css`
            display: none;

            @media screen and (max-width: 992px) {
              &.opened {
                display: block;
                position: absolute;
                background: linear-gradient(180deg, #ae58cf 0%, #7e3f97 100%);
                width: 100%;
                z-index: 1000;
              }
              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                  &:last-child {
                    a {
                      border-bottom: none;
                    }
                  }
                  a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: white;
                    padding: 20px 15px;
                    text-decoration: none;
                    border-bottom: 1px solid white;
                    margin: 0 15px;
                  }
                  &:hover {
                    background-color: #01aef1;
                    color: white;
                    text-decoration: none;
                  }
                  &:active {
                    background-color: #01aef1;
                    color: white;
                  }
                }
              }

              svg {
                color: white;
                float: right;
                path {
                  color: inherit;
                }
              }
            }
          `}
        >
          <ul className='mobile-nav-links'>
            <li>
              <a href='/about'>
                ABOUT HODGKIN LYMPHOMA
                <img
                  loading='lazy'
                  src={arrowRight}
                  alt=''
                />
              </a>
            </li>
            <li>
              <a href='/videos'>
                VIDEOS
                <img loading='lazy' src={arrowRight} alt='' />
              </a>
            </li>
            <li>
              <a href='/resources'>
                RESOURCES
                <img loading='lazy' src={arrowRight} alt='' />
              </a>
            </li>
            <li>
              <a
                css={css``}
                href='/hodgkin-lymphoma-treatment-discussion-guide'
              // href={questionsPdf}
              // target='_blank'
              // rel='noopener noreferrer'
              >
                HODGKIN LYMPHOMA TREATMENT
                <br /> DISCUSSION GUIDE
                <img loading='lazy' src={arrowRight} alt='' />
              </a>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

// Header.propTypes = {
//   style: PropTypes.object.isRequired,
// }

export default Header
